:root {
  --font--body: "Graphik LCG", "Arial", sans-serif;
  --color--dark: #191919;
  --color--dark-80: #191919cc;
  --color--dark-60: #19191999;
  --color--dark-20: #19191933;
  --color--dark-10: #1919191a;
  --color--white: #fff;
  --color--white-2: #f2f2f2;
  --color--white-10: #ffffff1a;
  --color--gray: #cdcecf;
  --color--gray-dark: #393c3c;
  --color--light-gray: #e5e5e5;
  --transition-1: .3s linear;
  --transition-parallax: all .2s cubic-bezier(.25, .74, .22, .99);
  --items: 1;
  --gap: 40px;
  --index: calc(1vh + 1vw);
}

.hero__subtitle, footer, .header, .modal__middle, .modal-contact-me__content, .header__container, .md-swiper__btn, .link__see-case__icon-wrap, .link__see-case, .showreel__btn, .hero__link, .hero__subtitle span, .contact__link, .backdrop, .btn-cross, .footer__bottom .container {
  align-items: center;
  display: flex;
}

.footer__top .container {
  justify-content: space-between;
  display: flex;
}

.md-swiper__btn, .link__see-case__icon-wrap, .link__see-case, .showreel__btn, .hero__link, .hero__subtitle span, .contact__link, .backdrop, .btn-cross, .footer__bottom .container {
  justify-content: center;
}

.header__container {
  justify-content: space-between;
}

.motion-design__box-wrap, .motion-design__container, .skill-desc__list, .skill, .skills__list, .project__desc-wrap, .project, .projects__list, .projects__box-wrap, .projects__container, .hero__text-wrap, .hero__box, .hero__container, .footer-links-list {
  flex-direction: column;
  display: flex;
}

.modal__middle, .modal-contact-me__content {
  flex-direction: column;
}

.motion-design__swiper-wrap, .motion-design__box-wrap {
  flex: 0 0 100%;
}

.skills, .skills__inner .motion-design, .motion-design__inner, .section--slide, .section__inner, .section, .skill-desc__item, .link__see-case, .hero__container, .hero__overlay, .hero, .modal-contact-me__top, .modal-video__middle, .modal-showreel__middle, .btn-cross {
  position: relative;
}

.hero__overlay, .skill-desc__item:before, .link__see-case__icon-wrap, .modal-contact-me__btn--close, .btn-cross:before, .btn-cross:after, .visually-hidden {
  position: absolute;
}

.modal-video__btn--close, .modal-showreel__btn--close {
  position: fixed;
}

.btn-cross {
  cursor: pointer;
}

.btn-cross:before, .btn-cross:after {
  border-radius: 12px;
}

.md-swiper__btn, .link__see-case__icon-wrap, .showreel__btn:after, .showreel__btn, .hero__img, .hero__subtitle span, .modal-contact-me__btn--close {
  border-radius: 50%;
}

.hero__container {
  transition: var(--transition-parallax);
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

.header {
  z-index: 50;
  width: 100%;
  min-height: var(--header--height);
  --color: var(--color--white);
  --bg-color: transparent;
  color: var(--color, --color--white);
  background-color: var(--bg-color, transparent);
  transition: var(--transition-1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

body.mobileMenuIsOpen .header {
  color: var(--color--white);
  background-color: var(--color--dark);
}

.header--transparent-dark {
  --bg-color: transparent;
  --color: var(--color--dark);
}

.header--dark {
  --bg-color: var(--color--dark);
  --color: var(--color--white);
}

.header__container {
  flex-wrap: wrap;
  gap: 12px;
}

@media (max-width: 767px) {
  .header__container {
    justify-content: space-between;
    align-items: center;
  }
}

footer {
  z-index: 10;
  color: var(--color--dark);
  background-color: var(--color--white);
  border-top: 1px solid var(--color--dark-10);
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
  padding: 48px 0 24px;
  position: relative;
}

@media (min-width: 768px) {
  footer {
    gap: 24px;
  }
}

.footer__top, .footer__bottom {
  width: 100%;
}

@media (max-width: 767px) {
  .footer__top .container {
    flex-direction: column;
  }
}

.footer-links-item {
  padding: 0 0 16px;
}

@media (max-width: 767px) {
  .footer-links-item {
    border-top: 1px solid var(--color--dark-10);
    padding: 16px 0;
  }
}

.footer__box {
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .footer__box {
    order: -1;
  }
}

.footer-link {
  height: 16px;
  background-color: #0000;
  padding: 0;
  line-height: 1.2;
  position: relative;
}

.footer-link:before {
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--color--dark);
  transition: all .2s linear;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.footer-link:hover:before, .footer-link:focus:before {
  width: 100%;
}

.btn-cross {
  width: 24px;
  height: 24px;
  --color: var(--color--dark);
  --line-width: 20px;
  background-color: #0000;
}

.btn-cross:before, .btn-cross:after {
  content: "";
  width: var(--line-width);
  height: 2px;
  background-color: var(--color);
}

.btn-cross:before {
  transform: rotate(-45deg);
}

.btn-cross:after {
  transform: rotate(45deg);
}

.modal-showreel__content {
  margin: 0 auto;
  transition: all .1s linear;
}

.modal-showreel.isHidden .modal-showreel__content {
  transform: scale(.7);
}

.modal-showreel__btn--close {
  background-color: var(--color--dark);
  --color: var(--color--white);
  z-index: 5;
  top: 24px;
  right: 24px;
}

.modal-showreel__iframe {
  min-width: 60vw;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
  min-height: 90vh;
  height: 90vh;
  aspect-ratio: 16 / 9;
  border: 0;
  margin-bottom: auto;
}

.modal-video__content {
  margin: 0 auto;
  transition: all .2s linear;
}

.modal-video.isHidden .modal-video__content {
  transform: scale(.7);
}

.modal-video__btn--close {
  background-color: var(--color--dark);
  --color: var(--color--white);
  z-index: 5;
  position: fixed;
  top: 24px;
  right: 24px;
}

.modal-video__player {
  min-width: 60vw;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
  aspect-ratio: 16 / 9;
  margin-bottom: auto;
}

.backdrop {
  z-index: 3000;
  background-color: #000000e6;
  padding: 40px;
  transition: all .1s linear;
  position: fixed;
  inset: 0;
  overflow: scroll;
}

.modal.isHidden .backdrop {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.modal-contact-me__backdrop {
  background-color: #393c3d;
}

.modal-contact-me__content {
  max-width: 520px;
  background-color: #0000;
  padding: 20px;
}

.modal-contact-me__btn--close {
  --line-width: 16px;
  --color: var(--color--dark);
  background-color: var(--color--white);
  top: -20px;
  right: -20px;
}

.modal-contact-me__title {
  color: #fffc;
  text-align: center;
  letter-spacing: -3.35px;
  margin-bottom: 24px;
  font-size: 52px;
  font-weight: 600;
  line-height: 118%;
}

.modal-contact-me__text {
  color: #fffc;
  text-align: center;
  letter-spacing: -1.4px;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 400;
  line-height: 142%;
}

.contact_list {
  gap: 16px;
  display: flex;
}

.contact__link {
  width: 128px;
  height: 40px;
  color: var(--color--white);
  background-color: var(--color--dark);
  border-radius: 40px;
  gap: 8px;
  padding: 0 8px;
  transition: all .3s linear;
}

.contact__link:focus-visible {
  outline: none;
}

.contact__link:hover, .contact__link:focus {
  color: var(--color--white);
}

.contact__link--telegram:hover, .contact__link--telegram:focus {
  background: #08c;
}

.contact__link--whatsapp:hover, .contact__link--whatsapp:focus {
  background: #25d366;
}

.contact__icon {
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
}

.contact__link--email {
  height: 40px;
  color: #fff;
  letter-spacing: -1.4px;
  border-radius: 40px;
  margin-bottom: 52px;
  padding: 4px 16px;
  font-size: 22px;
  font-weight: 600;
  line-height: 142.5%;
  text-decoration-line: underline;
  transition: all .3s linear;
  display: block;
}

.contact__link--email:hover, .contact__link--email:focus {
  background-color: var(--color--dark);
}

.hero {
  overflow: hidden;
}

.hero:after {
  content: "";
  z-index: 2;
  width: 1429.31px;
  height: 672.977px;
  pointer-events: none;
  background: url("bg-line-hero-3.11c51a04.svg") 100% 100% no-repeat;
  position: absolute;
  top: -200px;
  right: -600px;
  transform: rotate(2.611deg);
}

@media (min-width: 480px) {
  .hero:after {
    top: -180px;
    right: -520px;
  }
}

@media (min-width: 768px) {
  .hero:after {
    top: -140px;
    right: -400px;
  }
}

@media (min-width: 980px) {
  .hero:after {
    right: -300px;
  }
}

@media (min-width: 1024px) {
  .hero:after {
    right: -200px;
  }
}

@media (min-width: 1200px) {
  .hero:after {
    right: -20px;
  }
}

.hero__overlay {
  background-color: var(--color--gray-dark);
  filter: drop-shadow(0 30px 68px #00000026);
  will-change: transform;
  transition: transform .2s cubic-bezier(.25, .74, .22, .99);
}

.hero__overlay:before {
  content: "";
  z-index: 1;
  width: 1429.31px;
  height: 672.977px;
  pointer-events: none;
  background: url("bg-line-hero-4.85a0cbd2.svg") 0 0 no-repeat;
  position: absolute;
  bottom: -240px;
  left: -5%;
  transform: rotate(2.611deg);
}

.hero__container {
  padding-top: calc(60px + var(--header--height));
  color: var(--color--white);
  z-index: 1;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .hero__container {
    padding-bottom: 24px;
  }
}

.hero__title {
  letter-spacing: -1.6px;
  color: var(--color--white-2);
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 500;
  line-height: 110%;
}

@media (max-width: 479px) {
  .hero__title {
    margin-bottom: 28px;
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .hero__title {
    width: 500px;
  }
}

@media (min-width: 1200px) {
  .hero__title {
    width: 620px;
    font-size: 62px;
  }
}

.hero__title span {
  color: var(--color--white-2);
}

.hero__subtitle {
  color: var(--color--white-2);
  letter-spacing: -1.4px;
  gap: 8px;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 500;
  line-height: 111.5%;
}

@media (max-width: 479px) {
  .hero__subtitle {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.hero__subtitle span {
  width: 38px;
  height: 38px;
  color: var(--color--white);
  background-color: var(--color--dark);
  flex: 0 0 38px;
  padding: 8px;
}

.hero__box {
  margin-top: auto;
}

@media (max-width: 767px) {
  .hero__box {
    --gap: 126px;
    gap: 12px;
  }
}

@media (min-width: 768px) {
  .hero__box {
    flex-direction: row;
    justify-content: space-between;
  }

  .hero__text-wrap {
    flex-direction: row;
    flex-basis: 1 0 calc((100% - var(--gap)) / 2);
    order: 2;
    align-items: center;
    gap: 24px;
  }
}

.hero__img {
  width: 100px;
  height: 100px;
  flex: 0 0 100px;
}

@media (max-width: 1023px) {
  .hero__img {
    display: none;
  }
}

.hero__text {
  letter-spacing: -1.2px;
  color: #f8f8f8d6;
  font-size: 19px;
  line-height: 27px;
}

@media (max-width: 479px) {
  .hero__text {
    font-size: 16px;
  }
}

@media (max-width: 336px) {
  .hero__text {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .hero__text {
    letter-spacing: -1.4px;
    max-width: 460px;
    font-size: 22px;
    line-height: 24px;
  }
}

.hero__links-list {
  gap: 40px;
  margin-top: auto;
  display: flex;
}

@media (min-width: 768px) {
  .hero__links-list {
    flex: 0 1 calc((100% - var(--gap)) / 2);
  }
}

.hero__link {
  color: var(--color--white);
  gap: 16px;
  position: relative;
}

@media (max-width: 479px) {
  .hero__link {
    font-size: 16px;
  }
}

.hero__link:before {
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--color--white);
  transition: all .2s linear;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.hero__link:hover:before, .hero__link:focus:before {
  width: 100%;
}

.showreel {
  max-height: var(--viewport-height);
  background: var(--color--dark);
}

.showreel__inner {
  height: 100vh;
  will-change: transform;
  transition: all .2s cubic-bezier(.25, .74, .22, .99);
  overflow-x: hidden;
}

.showreel__box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.showreel__iframe {
  pointer-events: none;
  width: 100%;
  max-width: none;
  height: auto;
  aspect-ratio: 16 / 9;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-aspect-ratio: 4 / 3) {
  .showreel__iframe {
    height: 100vh;
    width: 166.667vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-aspect-ratio: 3 / 4) {
  .showreel__iframe {
    height: 100vh;
    width: 166.667vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-aspect-ratio: 1) {
  .showreel__iframe {
    height: 100vh;
    width: 166.667vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-aspect-ratio: 683 / 450) {
  .showreel__iframe {
    height: 110vh;
    width: 200.667vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-aspect-ratio: 1193 / 600) {
  .showreel__iframe {
    height: 110vh;
    width: 200.667vh;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.showreel__btn {
  z-index: 2;
  width: 120px;
  height: 120px;
  color: var(--color--dark);
  background-color: var(--color--white);
  transition: var(--transition-1);
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.showreel__btn:hover, .showreel__btn :focus {
  color: var(--color--white);
  background-color: var(--color--dark);
}

.showreel__btn:after {
  content: "";
  z-index: -1;
  width: 128px;
  height: 128px;
  border: 2px solid;
  border-color: var(--color--gray);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projects {
  z-index: 1;
}

.projects__inner {
  background-color: var(--color--white);
  padding: 48px 0 60px;
}

@media (min-width: 768px) {
  .projects__inner {
    padding: 70px 0 140px;
  }
}

.projects__container {
  --gap: 40px;
  gap: 52px;
}

.projects__box-wrap {
  gap: var(--gap);
}

@media (max-width: 767px) {
  .projects__box-wrap {
    gap: 16px;
    padding-bottom: 48px;
    position: relative;
  }
}

@media (min-width: 768px) {
  .projects__box-wrap {
    flex-direction: row;
  }
}

.projects__box {
  width: 100%;
}

@media (min-width: 768px) {
  .projects__box {
    flex: 0 0 calc((100% - var(--gap)) / 2);
    width: 0 0 calc((100% - var(--gap)) / 2);
  }
}

.projects__title {
  font-size: 32px;
  font-weight: 600;
}

.projects__text {
  letter-spacing: -1.2px;
  color: var(--color--dark-80);
  font-size: 19px;
  line-height: 142.5%;
}

@media (min-width: 768px) {
  .projects__text {
    letter-spacing: -1.4px;
    font-size: 22px;
    font-weight: 500;
    line-height: 111.5%;
  }
}

.projects__list {
  flex: 0 0 100%;
  gap: 60px;
}

@media (min-width: 1024px) {
  .projects__list {
    gap: 70px;
  }
}

.project {
  background-color: var(--color--white);
  gap: 36px;
}

@media (min-width: 1024px) {
  .project {
    flex-direction: row;
    align-items: center;
    gap: 48px;
  }
}

@media (min-width: 1440px) {
  .project {
    gap: 68px;
  }
}

.project__img-wrap {
  max-width: 715px;
  flex: 0 0 60%;
  position: relative;
  overflow: hidden;
}

.project__img {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  object-fit: cover;
  transition: var(--transition-1);
}

.project__link--rotate:hover .project__img, .project__link--rotate:focus .project__img {
  transform: scale(1.15)rotate(2deg);
}

.project__desc-wrap {
  flex-grow: 1;
  gap: 24px;
}

.project__title {
  letter-spacing: .141px;
  font-size: 22px;
  font-weight: 500;
  line-height: 94%;
}

.project__link--video {
  width: 100%;
  height: auto;
  pointer-events: none;
  position: absolute;
  top: -4.6%;
  left: 0;
}

.link__see-case {
  width: 136px;
  height: 32px;
  letter-spacing: .08px;
  text-transform: uppercase;
  color: var(--color--dark);
  border: 2px solid var(--color--dark);
  border-radius: 17px;
  gap: 16px;
  margin-right: 46px;
  font-size: 12px;
  font-weight: 500;
  transition: all .2s linear;
}

.link__see-case:hover, .link__see-case:focus, .link__see-case:hover .link__see-case__icon-wrap, .link__see-case:focus .link__see-case__icon-wrap {
  color: var(--color--white);
  border-color: var(--color--dark);
  background-color: var(--color--dark);
}

.link__see-case__icon-wrap {
  width: 30px;
  height: 30px;
  color: var(--color--dark);
  background-color: var(--color--white);
  flex: 0 0 30px;
  transition: all .2s linear;
  right: -36px;
}

.project__img--video-cover {
  z-index: 1;
  transition: opacity .3s ease-in-out;
  position: relative;
}

.project__link--play-video:hover .project__img--video-cover, .project__link--play-video:focus .project__img--video-cover {
  opacity: 0;
  z-index: -1;
}

.skills {
  z-index: 2;
}

.skills__inner {
  color: var(--color--white);
  background-color: #2b2d2e;
  padding: 50px 0 36px;
  transition: all .2s cubic-bezier(.25, .74, .22, .99);
}

@media (min-width: 768px) {
  .skills__inner {
    padding: 60px 0 200px;
  }
}

@media (min-width: 1024px) {
  .skills__inner {
    filter: grayscale();
    background-image: url("http://toniaux.com/images/skills/bg-x2.webp");
    background-position: right -300px bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (min-width: 1024px) and (min-device-pixel-ratio: 2), (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 1024px) and (min-resolution: 192dpi), (min-width: 1024px) and (min-resolution: 2dppx) {
  .skills__inner {
    background-image: url("http://toniaux.com/images/skills/bg-x3.webp");
  }
}

@media (min-width: 1024px) and (min-device-pixel-ratio: 3), (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 3), (min-width: 1024px) and (min-resolution: 288dpi), (min-width: 1024px) and (min-resolution: 3dppx) {
  .skills__inner {
    background-image: url("http://toniaux.com/images/skills/bg-x3.webp");
  }
}

@media (min-width: 1200px) {
  .skills__inner {
    background-position: right -200px bottom;
  }
}

@media (min-width: 1440px) {
  .skills__inner {
    background-position: 100% 100%;
  }
}

.skills__title {
  width: 230px;
  color: var(--color--white);
  letter-spacing: .1px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 300;
  line-height: 130.5%;
}

@media (min-width: 1024px) {
  .skills__box {
    max-width: 600px;
  }
}

.skills__list {
  gap: 48px;
}

.skill {
  gap: 16px;
}

.skill__title {
  letter-spacing: -1.4px;
  color: var(--color--white);
  pointer-events: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
}

.skill-desc__list {
  gap: 16px;
  padding: 0 34px;
}

.skill-desc__item:before {
  content: "";
  height: 1px;
  width: 10px;
  background-color: #fff;
  top: 12px;
  left: -18px;
}

.motion-design {
  z-index: 8;
}

.motion-design__inner {
  background-color: var(--color--white);
  padding: 48px 0 60px;
}

@media (min-width: 768px) {
  .motion-design__inner {
    padding: 70px 0 140px;
  }
}

.motion-design__container {
  min-width: 0;
  --gap: 40px;
  gap: 60px;
  position: relative;
}

.motion-design__box-wrap {
  gap: var(--gap);
}

@media (max-width: 767px) {
  .motion-design__box-wrap {
    gap: 16px;
    padding-bottom: 48px;
    position: relative;
  }
}

@media (min-width: 768px) {
  .motion-design__box-wrap {
    flex-direction: row;
  }
}

.motion-design__box {
  width: 100%;
}

@media (min-width: 768px) {
  .motion-design__box {
    flex: 0 0 calc((100% - var(--gap)) / 2);
    width: 0 0 calc((100% - var(--gap)) / 2);
  }
}

.motion-design__list {
  max-width: 100%;
  display: flex;
}

.motion-design__item {
  flex-direction: column;
  gap: 28px;
  display: flex;
}

.md-project__img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  pointer-events: none;
}

.md-project__video {
  pointer-events: none;
}

.md-project__title {
  letter-spacing: .1px;
  font-weight: 500;
  line-height: 1.2;
}

.md-swiper__btns {
  gap: 8px;
  margin-top: 16px;
  display: flex;
}

@media (max-width: 767px) {
  .md-swiper__btns {
    position: absolute;
    top: calc(100% - 40px);
  }
}

.md-swiper__btn {
  width: 40px;
  height: 40px;
  background-color: var(--color--gray);
}

.md-swiper__btn[data-action="prev"] {
  transform: rotate(-90deg);
}

.md-swiper__btn[data-action="next"] {
  transform: rotate(90deg);
}

.section {
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.25, .74, .22, .99);
}

.section:not(:first-of-type) {
  margin-top: -1px;
}

.section--slide, .section__inner {
  min-height: 100vh;
  min-height: var(--viewport-height);
  will-change: transform;
}

.section--slide.projects, .section__inner.projects, .section--slide.projects__inner, .section__inner.projects__inner, .section--slide.skills, .section__inner.skills, .section--slide.skills__inner, .section__inner.skills__inner, .section--slide.motion-design, .section__inner.motion-design, .section--slide.motion-design__inner, .section__inner.motion-design__inner {
  min-height: 100%;
}

.hero {
  z-index: 1;
}

.hero__container {
  z-index: 3;
  height: 100vh;
}

.hero__overlay {
  height: 100vh;
  opacity: 1;
  will-change: transform;
  inset: 0;
}

.skills, .skills__inner .motion-design, .motion-design__inner {
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--viewport-height, 104vh) * .25 + 60px);
}

body {
  background: linear-gradient(to bottom var(--color--gray-dark) 50%, var(--color--white) 50% 100%);
}

/*# sourceMappingURL=index.adada2d9.css.map */

.modal-contact-me__backdrop {
  background-color: #393C3D;
}

.modal-contact-me__content {
  @extend %flex-ac-dc;
  max-width: 520px;
  padding: 20px;
  
  background-color: transparent;
}
.modal-contact-me__top {
  @extend %relative;
}
.modal__middle {
  @extend %flex-ac-dc;
}

.modal-contact-me__btn--close {
  @extend %absolute;
  top: -20px;
  right: -20px;

  --line-width: 16px;
  --color: var(--color--dark);
  @extend %border-radius-50;
  background-color: var(--color--white);
}
.modal-contact-me__title {
  margin-bottom: 24px;

  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-size: 52px;
  font-weight: 600;
  line-height: 118%; /* 62.709px */
  letter-spacing: -3.35px;
}
.modal-contact-me__text {
  margin-bottom: 12px;

  color: rgba(255, 255, 255, 0.80);
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  line-height: 142%; /* 31.24px */
  letter-spacing: -1.4px;
}
.contact_list {
  display: flex;
  gap: 16px;
}

.contact__link {
  @extend %flex-ac-jc;
  gap: 8px;
  width: 128px;
  height: 40px;
  padding: 0 8px;

  color: var(--color--white);
  background-color: var(--color--dark);
  border-radius: 40px;

  transition: all 300ms linear;
}

.contact__link:focus-visible {outline: none;}
.contact__link:hover, .contact__link:focus {color: var(--color--white);}
.contact__link--telegram:hover, .contact__link--telegram:focus {background: #08c;};
// .contact__link--viber:hover, .contact__link--viber:focus {background: #59267c;};
.contact__link--whatsapp:hover, .contact__link--whatsapp:focus {background: #25d366;};

.contact__icon {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
}

.contact__link--email {
  display: block;
  height: 40px;
  margin-bottom: 52px;
  padding: 4px 16px;

  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 142.5%; /* 31.35px */
  letter-spacing: -1.4px;
  text-decoration-line: underline;
  transition: all 300ms linear;

  border-radius: 40px;

  &:hover, &:focus {
    background-color: var(--color--dark);
  }
}

.btn-cross {
  @extend %relative;
  
  @extend %flex-ac-jc;
  width: 24px;
  height: 24px;

  background-color: transparent;
  // background-color: red;
  --color: var(--color--dark);
  --line-width: 20px;
  @extend %cursor;
}

.btn-cross::before,
.btn-cross::after {
  content: '';
  @extend %absolute;

  width: var(--line-width);
  height: 2px;

  background-color: var(--color);
  @extend %border-radius;
}

.btn-cross::before {
  // @extend %absolute;
  transform: rotate(-45deg);
  transform: translateY(8px);
}

.btn-cross::after {
  // @extend %absolute;
  transform: rotate(45deg);
  transform: translateY(-8px);
}

.modal-showreel__content {
  margin: 0 auto;

  transition: all 100ms linear;

  .modal-showreel.isHidden & {
    transform: scale(0.7);
  }
}

.modal-showreel__middle {
   @extend %relative;
}

.modal-showreel__btn--close {
  // position: fixed;
  @extend %fixed;
  top: 24px;
  right: 24px;

  background-color: var(--color--dark);
  --color: var(--color--white);

  z-index: 5;
}

.modal-showreel__iframe {
  min-width: 60vw;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
  min-height: 90vh;
  height: 90vh;
  margin-bottom: auto;

  aspect-ratio: 16 / 9;
  border: 0;
}

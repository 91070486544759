.modal-video__content {
  margin: 0 auto;

  transition: all 200ms linear;

  .modal-video.isHidden & {
    transform: scale(0.7);
  }
}

.modal-video__middle {
  @extend %relative;
}

.modal-video__btn--close {
  position: fixed;
  @extend %fixed;
  top: 24px;
  right: 24px;

  background-color: var(--color--dark);
  --color: var(--color--white);

  z-index: 5;
}
.modal-video__player {
  min-width: 60vw;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;

  margin-bottom: auto;

  aspect-ratio: 16 / 9;
}

.motion-design {
  z-index: 8;
}
.motion-design__inner {
  padding: 48px 0 60px;
  background-color: var(--color--white);

  @include breakpoint-min($tablet) {  padding: 70px 0 140px;}
}
.motion-design__container {
  position: relative;
  @extend %flex-dc;
  gap: 60px;
  min-width: 0;

  --gap: 40px;
}
.motion-design__box-wrap {
  @extend %flex-100;
  @extend %flex-dc;
  gap: var(--gap);

  @include breakpoint-max($tablet - 1) {
    position: relative;
    gap: 16px; 

    padding-bottom: 48px;
  }
  @include breakpoint-min($tablet) { flex-direction: row; }
}
.motion-design__swiper-wrap {
  @extend %flex-100;
}

.motion-design__box {
  width: 100%;

  @include breakpoint-min($tablet) {
    flex: 0 0 calc((100% - var(--gap)) / 2);
    width: 0 0 calc((100% - var(--gap)) / 2);
  }
}

.motion-design__list {
  max-width: 100%;
  display: flex;
}

.motion-design__item {
  display: flex;
  flex-direction: column;
  gap: 28px;
  // padding-bottom: 8px;
}

.md-project__img {
  aspect-ratio: 16 / 9;
  object-fit: cover;

  pointer-events: none;
}
.md-project__video {
  pointer-events: none;
}
.md-project__title {
  font-weight: 500;
  line-height: 1.2; /* 15.04px */
  letter-spacing: 0.1px;
}

.md-swiper__btns {
  display: flex;
  gap: 8px;
  margin-top: 16px;

  @include breakpoint-max($tablet - 1) {
    position: absolute;
    top: calc(100% - 40px)
  }
}

.md-swiper__btn {
  @extend %flex-ac-jc;
  width: 40px;
  height: 40px;

  background-color: var(--color--gray);
  @extend %border-radius-50;

  &[data-action="prev"] {transform: rotate(-90deg);}
  &[data-action="next"] {transform: rotate(90deg);}
}

// .motion-design-swiper__btns .swiper-button-prev,
// .motion-design-swiper__btns .swiper-button-next {
//   position: static;

//   width: 20px;
//   height: 20px;
// }

.projects {
  z-index: 1;
}
.projects__inner {
  padding: 48px 0 60px;
  background-color: var(--color--white);
  @include breakpoint-min($tablet) {  padding: 70px 0 140px;}
}

.projects__container {
  @extend %flex-dc;
  gap: 52px;

  --gap: 40px;
}
.projects__box-wrap {
  @extend %flex-dc;
  gap: var(--gap);

  @include breakpoint-max($tablet - 1) {
    position: relative;
    gap: 16px; 

    padding-bottom: 48px;
  }
  @include breakpoint-min($tablet) { flex-direction: row; }
}

.projects__box {
  width: 100%;

  @include breakpoint-min($tablet) {
    flex: 0 0 calc((100% - var(--gap)) / 2);
    width: 0 0 calc((100% - var(--gap)) / 2);
  }
}
.projects__title {
  font-size: 32px;
  font-weight: 600;
}
.projects__text {
  font-size: 19px;
  line-height: 142.5%; /* 27.075px */
  letter-spacing: -1.2px;
  color: var(--color--dark-80);

  @include breakpoint-min($tablet) { 
    font-size: 22px;
    font-weight: 500;
    line-height: 111.5%; 
    letter-spacing: -1.4px;
  }
}
.projects__list {
  flex: 0 0 100%;
  @extend %flex-dc;
  gap: 60px; 

  @include breakpoint-min($laptop) { gap: 70px; }
}

.project {
  @extend %flex-dc;
  gap: 36px;

  background-color: var(--color--white);

  @include breakpoint-min($laptop) {
    flex-direction: row;
    align-items: center;
    gap: 48px;
  }
  @include breakpoint-min($desktopL) { gap: 68px; }
}
.project__img-wrap {
  flex: 0 0 60%;
  position: relative;
  overflow: hidden;

  max-width: 715px;
}
.project__img {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  object-fit: cover;

  transition: var(--transition-1);

  .project__link--rotate:hover &,
  .project__link--rotate:focus & {
    transform: scale(1.15) rotate(2deg);
  }
}

.project__desc-wrap {
  flex-grow: 1;
  @extend %flex-dc;
  gap: 24px;
}
.project__title {
  font-weight: 600;
  font-size: 22px;
  font-weight: 500;
  line-height: 94%; /* 20.68px */
  letter-spacing: 0.141px;
}

// .project__link--coming-soon {
//  position: relative;

//  transition: 400ms ease-in-out;
// }
// .project__link--coming-soon::before {
//   content: '';
//   position: absolute;
//   top: -100%;
//   left: 0;
//   right: 0;
//   bottom: 0;

//   transition: 400ms ease-in-out;
// }
// .project__link--coming-soon::after {
//   content: 'Coming soon';
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%) scale(0.3);

//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 134px;
//   height: 32px;
//   padding: 8px 16px;

//   font-size: 17px;
//   line-height: 14px;
//   letter-spacing: -0.8px;
//   color: var(--color--dark-80);

//   opacity: 0;
//   background-color: var(--color--white);
//   border-radius: 30px;
//   transition: 400ms ease-in-out;
// }

// .project__link--coming-soon:hover,
// .project__link--coming-soon:focus {
//   &::before {
//     background-color: rgba(255, 255, 255,  0.2);
//     backdrop-filter: blur(2px);
//   }
//   &::after {
//     opacity: 1;
//     transform: translate(-50%, -50%) scale(1);
//   }
// }

.project__link--video {
  position: absolute;
  top: -4.6%;
  left: 0;

  width: 100%;
  height: auto;

  pointer-events: none;
}

.link__see-case {
  @extend %relative;

  @extend %flex-ac-jc;
  gap: 16px;
  width: 136px;
  height: 32px;
  margin-right: 46px;

  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: var(--color--dark);

  border: 2px solid var(--color--dark);
  border-radius: 17px;
  transition: all 200ms linear;

  &:hover, &:focus {
    color: var(--color--white);
    border-color: var(--color--dark);
    background-color: var(--color--dark);

    .link__see-case__icon-wrap { 
      color: var(--color--white);
      border-color: var(--color--dark);
      background-color: var(--color--dark);
    }
  }
}

.link__see-case__icon-wrap {
  @extend %absolute;
  right: -36px;

  flex: 0 0 30px;
  @extend %flex-ac-jc;
  width: 30px;
  height: 30px;

  @extend %border-radius-50;
  color: var(--color--dark);
  background-color: var(--color--white);
  // border: 1px solid var(--color--dark-10);
  transition: all 200ms linear;
}

.project__img--video-cover {
  position: relative;
  z-index: 1;
  transition: opacity 300ms ease-in-out;

  .project__link--play-video:hover &,
  .project__link--play-video:focus & {
    opacity: 0;
  
    z-index: -1;
  }
}

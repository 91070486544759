// common styles
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';

// components styles
@import '/src/components/header/header.scss';
@import '/src/components/footer/footer.scss';
@import '/src/components/modal/modal.scss';

// sections styles
@import '/src/pages/home/section/hero/hero.scss';
@import '/src/pages/home/section/showreel/showreel.scss';
@import '/src/pages/home/section/projects/projects.scss';
@import '/src/pages/home/section/skills/skills.scss';
@import '/src/pages/home/section/motion-design/motion-design.scss';

// effect styles
@import '/src/components/parallax/parallax.scss';

html {
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--viewport-height, 104vh) * 0.25 + 60px);

  // @include breakpoint-min($tablet) {
  //   scroll-padding-top: calc(var(--viewport-height, 100vh)* 0.25 + 60px);
  // }
}

body {
  background: linear-gradient(to bottom var(--color--gray-dark) 50%, var(--color--white) 50% 100%);
}

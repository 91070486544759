footer {
  position: relative;
  z-index: 10;

  @extend %flex-ac;
  flex-direction: column;
  gap: 20px;
  margin-top: auto;
  padding: 48px 0 24px;

  color: var(--color--dark);

  background-color: var(--color--white);
  border-top: 1px solid var(--color--dark-10);

  @include breakpoint-min($tablet) {
    gap: 24px;
  }
}

.footer__top,
.footer__bottom {
  width: 100%;
  // height: 40px;
  // padding: 8px 0;
}
.footer__top .container {
  // @extend %flex-dc;
  @extend %flex-jsb;

  @include breakpoint-max($tablet - 1) {flex-direction: column}
}
.footer__bottom .container {
  @extend %flex-ac-jc;
}

.footer-links-list {
  @extend %flex-dc;
}
.footer-links-item {
  padding: 0 0 16px;

 @include breakpoint-max($tablet - 1) {
    padding: 16px 0;

    border-top: 1px solid var(--color--dark-10);
  }

}
.footer__box {
  margin-bottom: 16px;
  @include breakpoint-max($tablet - 1) {
    order: -1;
  }
}


.footer-link {
  position: relative;
  padding: 0;
  height: 16px;
  line-height: 1.2;

  background-color: transparent;
}
.footer-link::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;

  display: block;
  width: 0%;
  height: 1px;

  background-color: var(--color--dark);
  transition: all 200ms linear;
}

.footer-link:hover::before,
.footer-link:focus::before {
  width: 100%;
}
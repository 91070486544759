.hero {
  @extend %relative;
  overflow: hidden;
}
// .hero::before {
//   content: '';
//   position: absolute;
//   z-index: 1;

//   top: 200px;
//   left: -5%;
//   width: 1429.312px;
//   height: 672.977px;
//   transform: rotate(2.611deg);

//   background: url('/static/images/icons/bg-line-hero-4.svg');
//   background-repeat: no-repeat;
//   background-position: top left;
//   pointer-events: none;
// }
.hero::after {
  content: '';
  position: absolute;
  z-index: 2;

  top: -200px;
  right: -600px;
  
  width: 1429.312px;
  height: 672.977px;
  transform: rotate(2.611deg);

  background: url('/static/images/icons/bg-line-hero-3.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;

  @include breakpoint-min($mobile) { top: -180px; right: -520px } 
  @include breakpoint-min($tablet) { top: -140px; right: -400px} 
  @include breakpoint-min(980px) { right: -300px} 
  @include breakpoint-min($laptop) { right: -200px} 
  @include breakpoint-min($desktop) { right: -20px} 
}

.hero__overlay {
  @extend %relative;
  background-color: var(--color--gray-dark);
  filter: drop-shadow(0px 30px 68px rgba(0, 0, 0, 0.15));
  will-change: transform;
  transition: transform 200ms cubic-bezier(.25,.74,.22,.99);
}
.hero__overlay::before {
  content: '';
  position: absolute;
  z-index: 1;

  bottom: -240px;
  left: -5%;
  width: 1429.312px;
  height: 672.977px;
  transform: rotate(2.611deg);

  background: url('/static/images/icons/bg-line-hero-4.svg');
  background-repeat: no-repeat;
  background-position: top left;
  pointer-events: none;
}

.hero__container { 
  @extend %relative;
  
  @extend %flex-dc;
  padding-top: calc(60px + var(--header--height));
  padding-bottom: 60px;
  
  color: var(--color--white);

  @extend %transition;
  z-index: 1;

  @include breakpoint-max($tablet - 1) {
    padding-bottom: 24px;
  }
}


.hero__title {
  margin-bottom: 40px;

  font-size: 48px;
  font-weight: 500;
  line-height: 110%; /* 52.8px */
  letter-spacing: -1.6px;
  color: var(--color--white-2);

  @include breakpoint-max($mobile - 1) { 
    margin-bottom: 28px;
    font-size: 36px
  }
  @include breakpoint-min($tablet) { width: 500px}
  @include breakpoint-min($desktop) { width: 620px; font-size: 62px;}
}
.hero__title span {color: var(--color--white-2);};
.hero__subtitle {
  @extend %flex-ac;
  gap: 8px;
  margin-bottom: 30px;

  color: var(--color--white-2);
  font-size: 22px;
  font-weight: 500;
  line-height: 111.5%; 
  letter-spacing: -1.4px;

  @include breakpoint-max($mobile - 1) {
    margin-bottom: 20px;
    font-size: 20px
  }
}
.hero__subtitle span {
  flex: 0 0 38px;
  @extend %flex-ac-jc;
  width: 38px;
  height: 38px;
  padding: 8px;

  color: var(--color--white);

  background-color: var(--color--dark);
  @extend %border-radius-50;
}

.hero__box {
  @extend %flex-dc;
  margin-top: auto;
  
  @include breakpoint-max($tablet - 1) {
    gap: 12px;
    --gap: 126px;
  }
  @include breakpoint-min($tablet) {
    justify-content: space-between;
    flex-direction: row;
  }
}
.hero__text-wrap {
  @extend %flex-dc;
 
  @include breakpoint-min($tablet) {
    flex-direction: row;
    align-items: center;
    gap: 24px;

    flex-basis: 1 0 calc(((100% - var(--gap)) / 2));
    // width: calc(((100% - var(--gap)) / 2));
    order: 2;
  }
}

.hero__img {
  flex: 0 0 100px;
  width: 100px;
  height: 100px;

  @extend %border-radius-50;

  @include breakpoint-max($laptop - 1) {display: none;}
}
.hero__text {
  font-size: 19px;
  line-height: 27px;
  letter-spacing: -1.2px;
  color: rgba(248, 248, 248, 0.84);

  @include breakpoint-max($mobile - 1) { font-size: 16px }
  @include breakpoint-max(336px) { font-size: 14px }
  @include breakpoint-min($tablet) { 
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -1.4px;
  }
  @include breakpoint-min($tablet) {max-width: 460px;}
}
.hero__links-list {
  display: flex;
  gap: 40px;
  margin-top: auto;

  @include breakpoint-min($tablet) {flex: 0 1 calc(((100% - var(--gap)) / 2));}
}

.hero__link {
  position: relative;
  @extend %flex-ac-jc;
  gap: 16px;

  color: var(--color--white);

  @include breakpoint-max($mobile - 1) { font-size: 16px }
}
.hero__link::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;

  display: block;
  width: 0%;
  height: 1px;

  background-color: var(--color--white);
  transition: all 200ms linear;
}

.hero__link:hover::before,
.hero__link:focus::before {
  width: 100%;
}
.skills {
  z-index: 2;
}
.skills__inner {
  padding: 50px 0 36px;

  color: var(--color--white);
  background-color: #2B2D2E;
  transition: all 200ms cubic-bezier(.25,.74,.22,.99);

  @include breakpoint-min($tablet) { padding: 60px 0 200px; }
  @include breakpoint-min($laptop) {
    // filter: saturate(100%);
    filter: grayscale(100%);
    background-image: url('http://toniaux.com/images/skills/bg-x2.webp');
    @include ratio-2 { background-image: url('http://toniaux.com/images/skills/bg-x3.webp')}
    @include ratio-3 { background-image: url('http://toniaux.com/images/skills/bg-x3.webp')}
  
    background-repeat: no-repeat;
    background-position: right -300px bottom;
    background-size: contain;
  }
  @include breakpoint-min($desktop) {
    background-position: right -200px bottom;
  }
  @include breakpoint-min($desktopL) {
    background-position: right bottom;
  }
}
.skills__title {
  width: 230px;
  margin-bottom: 50px;

  color: var(--color--white);
  font-size: 20px;
  // line-height: 20px;
  line-height: 130.5%; /* 26.1px */
  letter-spacing: 0.1px;
  font-weight: 300;
}
.skills__box {
  @include breakpoint-min($laptop) {
    max-width: 600px
  }
}

.skills__list {
  @extend %flex-dc;
  gap: 48px;
}

.skill {
  @extend %flex-dc;
  gap: 16px;
}


.skill__title {
  // padding: 20px 0;

  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1.4px;
  color: var(--color--white);

  pointer-events: none;
}
.skill-desc__list {
  @extend %flex-dc;
  gap: 16px;
  padding: 0 34px;
}
.skill-desc__item {
  @extend %relative;

  &:before {
    content: '';
    @extend %absolute;
    top: 12px;
    left:-18px;

    height: 1px;
    width: 10px;
    background-color: #fff;
  }
}

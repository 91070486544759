.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;

  @extend %flex-ac;
  width: 100%;
  min-height: var(--header--height);

  --color: var(--color--white);
  --bg-color: transparent; 

  color: var(--color, --color--white);
  background-color: var(--bg-color, transparent);
  transition: var(--transition-1);

  body.mobileMenuIsOpen & {
    color: var(--color--white);
    background-color: var(--color--dark);
  }
}
.header--transparent-dark { --bg-color: transparent; --color: var(--color--dark); }
.header--dark { --bg-color: var(--color--dark); --color: var(--color--white); }

.header__container {
  @extend %flex-ac-jsb;
  gap: 12px;
  flex-wrap: wrap;


  @include breakpoint-max($tablet - 1) {
    justify-content: space-between;
    align-items: center;
  }
}

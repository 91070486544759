.showreel {
  max-height: var(--viewport-height);

  background: var(--color--dark);
}
.showreel__inner {
  height: 100vh;
  overflow-x: hidden;

  will-change: transform;
  transition: all 200ms cubic-bezier(.25,.74,.22,.99);
}

.showreel__box {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

.showreel__iframe {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  
  width: 100%;
  max-width: none;
  height: auto;
  aspect-ratio: 16 / 9;

  border: 0;

  @media (max-aspect-ratio: 4 / 3) {
    top: 0;
    height: 100vh;
    width: 166.66667vh;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-aspect-ratio: 3 / 4) {
    top: 0;
    height: 100vh;
    width: 166.66667vh;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-aspect-ratio: 1 / 1) {
    top: 0;
    height: 100vh;
    width: 166.66667vh;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-aspect-ratio: 683 / 450) {
    top: 0;
    height: 110vh;
    width: 200.66667vh;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-aspect-ratio: 1193 / 600) {
    top: 0;
    height: 110vh;
    width: 200.66667vh;
    left: 50%;
    transform: translateX(-50%);
  }
}

.showreel__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @extend %flex-ac-jc;
  width: 120px;
  height: 120px;

  font-size: 16px;
  font-weight: 500;
  color: var(--color--dark);

  @extend %border-radius-50;
  background-color: var(--color--white);
  transition: var(--transition-1);

  &:hover, :focus {
    color: var(--color--white);
    background-color: var(--color--dark);
  }
}

.showreel__btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  width: 128px;
  height: 128px;

  @extend %border-radius-50;
  border: 2px solid;
  border-color: var(--color--gray);
  // border-color: var(--color--dark);
  // border-color: var(--color--white);
}
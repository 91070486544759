.section {
  @extend %relative;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(.25,.74,.22,.99);

  &:not(:first-of-type) { margin-top: -1px;}
}

.section--slide,
.section__inner {
  @extend %relative;
  min-height: 100vh;
  min-height: var(--viewport-height);
  will-change: transform;

  &.projects  { min-height: 100%; }
  &.projects__inner  { min-height: 100%; }
  &.skills  { min-height: 100%; }
  &.skills__inner  { min-height: 100%; }
  &.motion-design  { min-height: 100%; }
  &.motion-design__inner  { min-height: 100%; }

}

.hero {
  z-index: 1;
}

.hero__container { 
  z-index: 3;
  height: 100vh; 
}
.hero__overlay {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh;
  opacity: 1;
  will-change: transform;
}


.skills,
.skills__inner
.motion-design,
.motion-design__inner {
  @extend %relative;
  min-height: 100%;
}

@import '/src/components/btns/btn-cross/btn-cross.scss';
@import './modal-showreel.scss';
@import './modal-video.scss';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  @extend %flex-ac-jc;
  padding: 40px;
  
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 100ms linear;
  z-index: 3000;
  overflow: scroll;
  
  .modal.isHidden & {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

@import './modal-contact-me.scss';